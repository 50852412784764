/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import axios from "../../config/axios";

// component
import {
  Layout,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Row,
  Col,
  Select,
  Card,
  Typography,
  InputNumber,
} from "antd";

import { Content, Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import Sidebar from "../../component/Sidebar";

// constant
import { BASE_URL_BETA } from "../../constant/api";
import putSubcriptionPackage from "./utils/putSubscriptionPackage";
import postSubscriptionPackage from "./utils/postSubscriptionPackage";
import deleteSubcriptionPackage from "./utils/deleteSubscriptionPackage";
import { getSubscriptionPackage } from "./utils/getSubscriptionPackages";

const { Text } = Typography;

const SubscriptionPackage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [form] = Form.useForm();
  const [selectedSubscriptionPackage, setSelectedSubscriptionPackage] =
    useState<SubscriptionPackageList | null>(null);
  const [subscriptionPackageList, setSubscriptionPackageList] = useState<
    SubscriptionPackageList[]
  >([]);
  const [freeTrialDays, setFreeTrialDays] = useState(undefined);
  const [showModalForm, setShowModalForm] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [monthlyFee, setMonthlyFee] = useState(0);
  const [monthlyFeeAfterDiscount, setMonthlyFeeAfterDiscount] = useState(0);
  const [activePeriod, setActivePeriod] = useState(0);
  const [isActive, setIsActive] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [packageType, setPackageType] = useState<"addons" | "activation" | "">(
    "activation"
  );

  const handleShowModalForm = (data: any) => {
    setShowModalForm(true);
    setSelectedSubscriptionPackage(data);
    setTitle(data.title);
    setDescription(data.description);
    setMonthlyFee(data.monthly_fee);
    setPackageType(data?.package_type);
    setActivePeriod(data.active_period);
    setDiscountType(data.discount_type);
    setIsActive(data.is_active === "Aktif" ? "active" : "inactive");
    setDiscountAmount(data.discount_amount);
    setFreeTrialDays(data.free_trial_days);
    form.setFieldsValue({
      title: data.title,
      description: data.description,
      monthlyFee: data.monthly_fee,
      activePeriod: data.active_period,
      discountAmount: data.discount_amount,
    });
  };

  const handleAddnewSubscriptionPackage = () => {
    setSelectedSubscriptionPackage(null);
    setTitle("");
    setDescription("");
    setMonthlyFee(0);
    setActivePeriod(0);
    setDiscountType("percentage");
    setIsActive("inactive");
    setDiscountAmount(0);
    setShowModalForm(true);
    form.setFieldsValue({
      title: "",
      description: "",
      monthlyFee: "",
      activePeriod: "",
      discountAmount: "",
    });
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });

  interface SubscriptionPackageList {
    id: number;
    title: string;
    description: string;
    active_period: number;
    monthly_fee: number;
    is_active: string;
    discount_type: string;
    discount_amount: number;
    total_fee: number;
    package_type: "addons" | "activation";
    show_in_app: boolean;
    is_free_trial_package: boolean;
  }

  const getSubscriptionPackageList = async () => {
    setIsLoading(true);

    const { packageList, totalData } = await getSubscriptionPackage();
    setSubscriptionPackageList(packageList);
    setPagination({
      ...pagination,
      total: totalData,
    });
    setIsLoading(false);
  };

  const handleTableChange = async (pagination: any) => {
    try {
      setIsLoading(true);
      const url = `${BASE_URL_BETA}/admin/subscription/list`;

      const response = await axios.get(url);
      setSubscriptionPackageList(response.data.subscription_package);
      setPagination({
        ...pagination,
        total: response.data.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const onStatusChange = (status: string) => {
    setIsActive(status);
  };

  const onDiscountTypeChange = (status: string) => {
    setDiscountAmount(0);
    setDiscountType(status);
  };

  const formItemLayout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 100,
    },
  };

  const columns = [
    {
      title: "Judul",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Tipe paket",
      dataIndex: "package_type",
      key: "package_type",
    },
    {
      title: "Periode aktif",
      dataIndex: "active_period",
      key: "active_period",
    },
    {
      title: "Free trial",
      dataIndex: "free_trial_days",
      key: "free_trial_days",
      render: (data: any, row: any) => {
        return (
          <Row>{`${
            row.free_trial_days
              ? `${nominalFormat(row.free_trial_days)} hari`
              : "-"
          }`}</Row>
        );
      },
    },
    {
      title: "Biaya bulanan",
      dataIndex: "monthly_fee",
      key: "monthly_fee",
    },
    {
      title: "status",
      dataIndex: "is_active",
      key: "is_active",
    },
    {
      title: "Diskon tipe",
      dataIndex: "discount_type",
      key: "discount_type",
    },
    {
      title: "Diskon",
      dataIndex: "discount_amount",
      key: "discount_amount",
    },
    {
      title: "Aksi",
      dataIndex: "subscription_package_id",
      key: "subscription_package_id",
      render: (data: any, row: any) => {
        return (
          <Row>
            <Col>
              <Button onClick={() => handleShowModalForm(row)}>Edit</Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    setSelectedSubscriptionPackage(null);
    setShowModalForm(false);
    setPackageType("activation");
  };

  const handleClosePreviewModal = () => {
    setShowModalPreview(false);
  };

  const handleShowPreviewModal = () => {
    calculateTotalFee();
    setShowModalPreview(true);
  };

  const handleAddSubscriptionPackage = async () => {
    await postSubscriptionPackage({
      title,
      description,
      monthlyFee,
      activePeriod,
      isActive,
      discountType,
      discountAmount,
    });
    setShowModalForm(false);
    form.resetFields();
    await getSubscriptionPackageList();
  };

  const handleEditSubscriptionPackage = async () => {
    await putSubcriptionPackage({
      id: selectedSubscriptionPackage?.id,
      title,
      description,
      monthlyFee,
      activePeriod,
      isActive,
      discountType,
      discountAmount,
      freeTrialDays: freeTrialDays || 0,
      showInApp: !!selectedSubscriptionPackage?.show_in_app,
    });
    setShowModalForm(false);
    form.resetFields();
    await getSubscriptionPackageList();
  };

  const handleDeleteSubscriptonPackage = async () => {
    await deleteSubcriptionPackage(selectedSubscriptionPackage?.id);
    setShowModalForm(false);
    await getSubscriptionPackageList();
  };

  const renderFooter = () => {
    if (selectedSubscriptionPackage) {
      return [
        <Button
          disabled={
            packageType === "addons" ||
            selectedSubscriptionPackage.is_free_trial_package
          }
          onClick={handleDeleteSubscriptonPackage}
        >
          Hapus
        </Button>,
        <Button
          type='primary'
          onClick={() => {
            form.validateFields().then(() => {
              handleEditSubscriptionPackage();
            });
          }}
          form='modalForm'
          htmlType='submit'
          key='submit'
        >
          simpan
        </Button>,
      ];
    }

    return [
      <Button
        type='primary'
        onClick={() => {
          form.validateFields().then(() => {
            handleAddSubscriptionPackage();
          });
        }}
        form='modalForm'
        htmlType='submit'
        key='submit'
      >
        simpan
      </Button>,
    ];
  };

  const nominalFormat = (
    nominal: string | number | undefined,
    decimalNumber?: number
  ) => {
    return new Intl.NumberFormat("id").format(
      +(+nominal! || 0).toFixed(
        decimalNumber === 0 || decimalNumber ? decimalNumber : 0
      )
    );
  };

  const calculateTotalFee = () => {
    let totalFee = 0;
    totalFee = monthlyFee * activePeriod;

    if (discountType === "percentage") {
      totalFee = totalFee - (totalFee * discountAmount) / 100;
    } else {
      totalFee = totalFee - discountAmount;
    }

    setMonthlyFeeAfterDiscount(totalFee / activePeriod);
    setTotalFee(totalFee);
  };

  const defineMaxDiscountAmount = () => {
    let maxDiscountAmount = monthlyFee * activePeriod;
    if (discountType === "percentage") {
      maxDiscountAmount = 100;
    }
    return maxDiscountAmount;
  };

  useEffect(() => {
    getSubscriptionPackageList();
  }, []);

  return (
    <>
      {showModalForm ? (
        <Modal
          visible={showModalForm}
          onCancel={handleCloseModal}
          confirmLoading={isLoading}
          footer={renderFooter()}
        >
          <Modal
            visible={showModalPreview}
            onCancel={handleClosePreviewModal}
            zIndex={1000}
            footer={[
              <Button type='primary' onClick={handleClosePreviewModal}>
                Ok
              </Button>,
            ]}
          >
            <Row style={{ justifyContent: "center" }}>
              <Card style={{ width: 300, alignContent: "center" }}>
                <Row
                  style={{
                    fontSize: "14px",
                    color: "#18737C",
                  }}
                >
                  {title}
                </Row>
                <Row style={{ fontSize: "12px", fontStyle: "italic" }}>
                  {description}
                </Row>
                {discountAmount !== 0 ? (
                  <Row>
                    <Text
                      delete
                      style={{
                        fontSize: "12px",
                        marginTop: "10px",
                        color: "#DF4B2A",
                      }}
                    >
                      Rp{nominalFormat(monthlyFee)}
                    </Text>
                  </Row>
                ) : null}
                <Row style={{ fontSize: "26px" }}>
                  Rp{nominalFormat(monthlyFeeAfterDiscount)}/bln
                </Row>
                <Row style={{ fontSize: "12px" }}>
                  Total Rp{nominalFormat(totalFee)}
                </Row>

                {freeTrialDays && (
                  <Row style={{ fontSize: "12px", fontStyle: "italic" }}>
                    free trial {nominalFormat(freeTrialDays)} hari{" "}
                  </Row>
                )}
              </Card>
            </Row>
          </Modal>

          <Form
            {...formItemLayout}
            layout='vertical'
            form={form}
            id='modalForm'
          >
            <Form.Item
              label='Nama paket'
              name='title'
              rules={[
                {
                  required: true,
                  message: "Nama paket tidak boleh kosong",
                },
              ]}
            >
              <Input
                disabled={packageType === "addons"}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label='Deskripsi'
              name='description'
              rules={[
                { required: true, message: "Deskripsi tidak boleh kosong" },
              ]}
            >
              <Input
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </Form.Item>
            <Row>
              {!selectedSubscriptionPackage?.is_free_trial_package && (
                <Col md={12}>
                  <Row style={{ marginBottom: "7.5px" }}>Biaya bulanan</Row>
                  <Form.Item
                    name='monthlyFee'
                    rules={[
                      {
                        required: true,
                        message: "Biaya bulanan tidak boleh kosong",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "90%" }}
                      onChange={(value: any) => setMonthlyFee(value)}
                      value={monthlyFee}
                      min={0}
                    />
                  </Form.Item>
                </Col>
              )}
              {(selectedSubscriptionPackage?.package_type === "activation" &&
                !selectedSubscriptionPackage?.is_free_trial_package) ||
              !selectedSubscriptionPackage?.id ? (
                <Col md={12}>
                  <Row style={{ marginBottom: "7.5px" }}>
                    Periode aktif (bulan)
                  </Row>
                  <Form.Item
                    name='activePeriod'
                    rules={[
                      {
                        required: true,
                        message: "Periode aktif tidak boleh kosong",
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={packageType === "addons"}
                      onChange={(value: any) => setActivePeriod(value)}
                      value={activePeriod}
                      min={0}
                    />
                  </Form.Item>
                </Col>
              ) : null}
              {selectedSubscriptionPackage?.package_type === "addons" ||
              selectedSubscriptionPackage?.is_free_trial_package ? (
                <Col md={12}>
                  <Row style={{ marginBottom: "7.5px" }}>Free trial</Row>
                  <Form.Item name='freeTrial' rules={[]}>
                    <InputNumber
                      onChange={(value: any) => setFreeTrialDays(value)}
                      value={freeTrialDays}
                      defaultValue={freeTrialDays}
                      min={0}
                    />
                    &nbsp; hari
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
            {!selectedSubscriptionPackage?.is_free_trial_package && (
              <>
                <Row>
                  <span>Diskon paket</span>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Item>
                      <Select
                        value={discountType}
                        onChange={onDiscountTypeChange}
                      >
                        <Select.Option value='percentage'>%</Select.Option>
                        <Select.Option value='cash'>Rp</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name='discountAmount'
                      rules={[
                        {
                          min: 0,
                          max: defineMaxDiscountAmount(),
                          type: "number",
                          message: "Diskon melebihi harga asli",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "135px" }}
                        value={discountAmount}
                        onChange={(value: any) => setDiscountAmount(value)}
                        min={0}
                        type='number'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Row>
              <span>Status paket</span>
            </Row>
            <Select
              value={isActive}
              style={{ width: 180, marginBottom: "20px" }}
              onChange={onStatusChange}
            >
              <Select.Option value='active' key='active'>
                Aktif
              </Select.Option>
              <Select.Option value='inactive' key='inactive'>
                Draft/tidak aktif
              </Select.Option>
            </Select>

            <Form.Item>
              <Button type='primary' onClick={() => handleShowPreviewModal()}>
                Preview hasil
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      ) : null}

      <Layout style={{ height: "100vh" }} {...formItemLayout}>
        <Sider breakpoint='lg' collapsedWidth='0'>
          <Sidebar activeTab={"subscription-package"} />
        </Sider>
        <Layout>
          <Header style={{ background: "#fff" }}>
            <h1>Subscription Package</h1>
          </Header>
          <Content
            style={{
              padding: "24px",
              paddingBottom: "100px",
              overflowY: "scroll",
            }}
          >
            <Button
              type='primary'
              style={{ marginBottom: "30px" }}
              onClick={() => handleAddnewSubscriptionPackage()}
            >
              Tambah
            </Button>
            <Table
              dataSource={subscriptionPackageList}
              columns={columns}
              pagination={pagination}
              loading={isLoading}
              onChange={handleTableChange}
            />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default SubscriptionPackage;
