import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Layout,
  Table,
  Button,
  Row,
  Col,
  Modal,
  Input,
  notification,
  Select,
} from "antd";
import moment, { Moment } from "moment";
import debounce from "lodash/debounce";

// component
import Sidebar from "../../component/Sidebar";

// utils
import getUserList from "./utils/getUserList";
import postUserRole from "./utils/postUserRole";
import putUserRole from "./utils/putUserRole";

// type
import { User } from "../../types/user";
import { isInternalAccess } from "../../utils/roles";



const UserComponent = () => {
  const { Header, Content, Sider } = Layout;
  const { Search } = Input;
  const { Option } = Select;

  const [list, setList] = useState([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedRole, setSelectedRole] = useState<"admin" | "canvasser" | "sales">("sales");
  const [email, setEmail] = useState<string>("");
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const emailRef = useRef<any>(null);

  const userRole = String(localStorage.getItem("userRole"));

  const handleDeleteUser = (data: User) => {
    setModalVisible(true);
    setSelectedUser(data);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
    setModalVisible(false);
  };

  const handleAddUser = async () => {
    try {
      setIsLoading(true);
      const reg = new RegExp("@farmacare.id", "g");
      if (!email.toLowerCase().match(reg) && selectedRole !== "canvasser") {
        notification.error({
          message: "Email Sales & admin harus menggunakan @farmacare.id",
          duration: 5,
        });
        setIsLoading(false);
        return;
      }
    
      await postUserRole(email, selectedRole);
      setIsLoading(false);
      setEmail("");
      emailRef.current.handleReset();
      setSelectedUser(null);

      notification.success({
        message: "Berhasil merubah status user",
        duration: 3,
      });
    } catch (err) {}

    await getUser();
    setIsLoading(false);
  };

  const handleUpdateUser = async () => {
    try {
      setIsLoading(true);

      await putUserRole(selectedUser?.id, "client");
      setIsLoading(false);
      handleCloseModal();
      getUser();

      notification.success({
        message: "Berhasil merubah status user",
        duration: 3,
      });
    } catch (err) {}
    setIsLoading(false);
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Aksi",
      dataIndex: "id",
      key: "id",
      render: (val: any, data: User) => {
        return (
          <Row>
            <Col>
              <Button onClick={() => handleDeleteUser(data)}>Hapus</Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  const getUser = async () => {
    try {
      const listUser = await getUserList();
      setList(listUser);
    } catch (err) {}
  };

  const getFormattedList = useMemo(() => {
    let result = list.map((p: User) => {
      return {
        id: p.id,
        email: p.email,
        name: p.name,
        role: p.role,
      };
    });

    result = result.filter((p) => {
      const reg = new RegExp(search.toLocaleLowerCase(), "g");
      if (p.email.toLowerCase().match(reg) || p.name.toLowerCase().match(reg)) {
        return true;
      }

      return false;
    });

    return result;
  }, [list, search]);

  const onSearch = debounce((text: string) => {
    setSearch(text);
  }, 800);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onSelectRoleChange = (value: "admin" | "sales" | "canvasser") => {
    setSelectedRole(value);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Modal
        visible={modalVisible}
        onCancel={handleCloseModal}
        onOk={handleUpdateUser}
        confirmLoading={isLoading}
      >
        <Row>
          <Col>
            <h2>Hapus user!</h2>
            <Row>
              <p>Apakah kamu yakin ingin menghapus user ini?</p>
            </Row>
          </Col>
        </Row>
      </Modal>

      <Layout style={{ height: "100vh" }}>
        <Sider breakpoint="lg" collapsedWidth="0">
          <Sidebar activeTab={"user"} />
        </Sider>
        <Layout>
          <Header style={{ background: "#fff" }}>
            <h1>List User</h1>
          </Header>
          <Content
            style={{
              padding: "24px",
              paddingBottom: "100px",
              overflowY: "scroll",
            }}
          >
            <Row style={{ marginBottom: "16px" }}>
              <Col span={6}>
                <Search placeholder="input search text" onSearch={onSearch} />
              </Col>
              <Col span={6}>
                <Input
                  ref={emailRef}
                  placeholder="Masukan email"
                  onChange={(e) => onInputChange(e)}
                />
              </Col>
              <Col span={6}>
                <Select
                  defaultValue="sales"
                  onChange={onSelectRoleChange}
                  style={{ width: "100%" }}
                >
                  <Option value="sales">Sales</Option>
                  <Option value="canvasser">Canvasser</Option>
                  <Option value="admin">Admin</Option>
                </Select>
              </Col>
              <Col span={6}>
                <Button onClick={() => handleAddUser()}>Tambahkan</Button>
              </Col>
            </Row>
            <Table dataSource={getFormattedList} columns={columns} />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default UserComponent;
