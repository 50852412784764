import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

import { Pharmacy } from "../../types/pharmacy";
import { OrgTypeFilter, PaginationFilter } from "./interface";
import { PharmacyService } from "./pharmacyService";

export type WorkspaceFilter = { is_hidden?: boolean };

export const useGetPharmacyList = (
   filter: PaginationFilter & OrgTypeFilter & WorkspaceFilter,
   enabled: boolean,
   onError?: (err: any) => void
) => {
   const {
      data = { pharmacies: [], totalData: 0 },
      status,
      isFetching,
      error,
      isRefetching,
      refetch,
   } = useQuery(["pharmacyList", filter], async () => PharmacyService.getPharmacyList(filter), {
      select: (response: AxiosResponse) => {
         return {
            pharmacies: response.data?.company || [],
            totalData: response.data?.metadata?.total_data || 0,
         };
      },
      enabled,
      onError: (err: any) => onError && onError(err),
   });

   return {
      list: data.pharmacies as Pharmacy[],
      totalData: data.totalData,
      error: error as any,
      status,
      isFetching,
      isRefetching,
      refetch,
   };
};
