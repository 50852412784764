import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Row,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content, Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import { useEffect, useState } from "react";

import Sidebar from "../../component/Sidebar";
import ModalConfirmationPromo from "./ModalConfirm";
import {
  getPromo,
  UpdatePayloadPromoType,
  updaterPromo,
} from "./utils/servicePromotion";

const PromotionDefaultPage = () => {
  const [form, setForm] = useState<UpdatePayloadPromoType>({
    button_text: "",
    subtitle: "",
    title: "",
    start_date: "",
    end_date: "",
  });
  const [loading, setLoading] = useState(false);

  const handleSetForm = (e: any) => {
    const key = e.target.name;
    const value = e.target.value;
    if (key === "date") {
      setForm({
        ...form,
        start_date: value?.[0]?.format("YYYY-MM-DD") || "",
        end_date: value?.[1]?.format("YYYY-MM-DD") || "",
      });
    } else {
      setForm({ ...form, [key]: value });
    }
  };

  const fetch = async () => {
    setLoading(true);
    const data = await getPromo();
    if (data?.promo) {
      setForm(data?.promo);
    }
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    await updaterPromo(form);
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Layout style={{ height: "100vh" }} {...formItemLayout}>
      <Sider breakpoint='lg' collapsedWidth='0'>
        <Sidebar activeTab={"promotion"} />
      </Sider>
      <Layout>
        <Header style={{ background: "#fff" }}>
          <h1>Promotion</h1>
        </Header>
        <Content
          style={{
            padding: "25px",
            paddingBottom: "100px",
            overflowY: "scroll",
          }}
        >
          <Content
            style={{
              background: "white",
              padding: "35px 25px 25px 25px",
              boxShadow: "0.1px 0.1px 5px 1px #dedddd",
              borderRadius: "5px",
            }}
          >
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
              onFinish={(e) => {
                e?.preventDefault();
              }}
              layout='horizontal'
            >
              <Form.Item label='Title'>
                <Input
                  placeholder=''
                  onChange={handleSetForm}
                  value={form.title}
                  name='title'
                  size='large'
                  style={{ fontWeight: "bold" }}
                />
              </Form.Item>
              <Form.Item label='Content'>
                <TextArea
                  rows={4}
                  size='large'
                  onChange={handleSetForm}
                  name='subtitle'
                  value={form.subtitle}
                />
              </Form.Item>
              <Form.Item label='Buton text'>
                <Input
                  placeholder=''
                  onChange={handleSetForm}
                  name='button_text'
                  size='large'
                  value={form.button_text}
                />
              </Form.Item>
              <Form.Item label='Date'>
                <DatePicker.RangePicker
                  size='large'
                  value={[
                    moment(form.start_date).isValid()
                      ? moment(form.start_date)
                      : null,
                    moment(form.end_date).isValid()
                      ? moment(form.end_date)
                      : null,
                  ]}
                  format='YYYY-MM-DD'
                  onChange={(e) =>
                    handleSetForm({ target: { value: e, name: "date" } })
                  }
                />
              </Form.Item>
              <Form.Item label='Notes'>
                <Alert
                  message='For hide it form client board, just delete title and save it. For show in client board, just fill this form.'
                  type='info'
                  showIcon
                />
              </Form.Item>
              <Row>
                <Col span={11} />
                <Col
                  span={11}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <ModalConfirmationPromo
                    onConfirm={handleSave}
                    isLoading={loading}
                  />
                </Col>
              </Row>
            </Form>
          </Content>
          {form.button_text || form.subtitle || form.title ? (
            <Content
              style={{
                marginTop: "10px",
                background: "white",
                padding: "10px",
              }}
            >
              <Text style={{ fontWeight: "bold" }}>Review</Text>
              <Content
                style={{
                  background: "#fff8e6",
                  marginTop: "10px",
                  padding: "15px",
                  boxShadow: "0.1px 0.1px 5px 1px #dedddd",
                  borderRadius: "2px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <IconGift />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "calc(100% - 80px)",
                      }}
                    >
                      <Typography
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        {form?.title}
                      </Typography>
                      <Typography style={{ fontSize: "14px" }}>
                        {form?.subtitle}
                      </Typography>
                    </div>
                  </div>
                  <Button
                    type='primary'
                    style={{
                      background: "rgb(15, 76, 82)",
                      borderRadius: "5px",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {form.button_text}
                  </Button>
                </div>
              </Content>
            </Content>
          ) : null}
        </Content>
      </Layout>
    </Layout>
  );
};

export default PromotionDefaultPage;

const formItemLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 100,
  },
};

const IconGift = () => {
  return (
    <div style={{ width: "17px", height: "17px", margin: "5px 10px 0 0" }}>
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 464 464'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <path d='m394.33,105.596h-76.136c22.944-22.372 37.216-53.596 37.216-88.096 0-9.665-7.835-17.5-17.5-17.5-44.786,0-84.057,24.045-105.596,59.9-21.539-35.855-60.81-59.9-105.595-59.9-9.665,0-17.5,7.835-17.5,17.5 0,34.5 14.273,65.724 37.216,88.096h-76.137c-9.665,0-17.5,7.835-17.5,17.5v324.033c0,9.665 7.835,17.5 17.5,17.5h324.032c9.665,0 17.5-7.835 17.5-17.5v-324.033c0-9.665-7.835-17.5-17.5-17.5zm-17.5,162.016h-127.016v-127.016h127.016v127.016zm-58.646-230.385c-7.525,32.765-33.378,58.618-66.144,66.143 7.526-32.766 33.379-58.619 66.144-66.143zm-105.596,66.142c-32.765-7.525-58.618-33.378-66.143-66.143 32.765,7.525 58.618,33.378 66.143,66.143zm2.226,37.227v127.016h-127.016v-127.016h127.016zm-127.016,162.016h127.016v127.017h-127.016v-127.017zm162.016,127.016v-127.016h127.016v127.017h-127.016z' />
      </svg>
    </div>
  );
};
