import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useGetPharmacyList } from "../../../../service/pharmacy/usePharmacyService";
import { useGetProductHistoryList } from "../../../../service/product/useProductService";

export const useTaxReportHistory = () => {
   const history = useHistory();

   const [searchName, setSearchName] = useState("");
   const [filter, setFilter] = useState({
      current: 1,
      pageSize: 50,
      total: 0,
      company_id: 0,
      product_name: "",
   });
   const {
      list: productList,
      totalData,
      isFetching,
   } = useGetProductHistoryList(!!filter.company_id, filter);

   const query = useMemo(() => {
      return queryString.parse(history.location.search);
   }, [history.location.search]);

   const { list: pharmacies, isFetching: fetchPharmacies } = useGetPharmacyList(
      { pageSize: 50, current: 1, search: (query.q || "") as string },
      true
   );

   const filteredPharmacies = useMemo(() => {
      return pharmacies;
   }, [pharmacies]);

   const updateQuery = (val: any) => {
      const q = queryString.parse(history.location.search);
      const qString = queryString.stringify({ ...q, ...val });
      history.replace(`/tax-report-history?${qString}`);
   };

   const onChangePharmacy = (val: string) => {
      setFilter({ ...filter, total: totalData, company_id: +val });
      const findPharmacy = pharmacies.find((p) => p.id.toString() === val);
      updateQuery({ pharmacyId: val, q: findPharmacy?.name || "" });
   };

   const onChangeSearchPharmacyFilter = (val: string) => {
      updateQuery({ q: val });
   };

   const onNavProductDetail = (id: string) => {
      history.push(`/tax-report-history/${id}`);
   };

   const onChangeProductNameFilter = debounce(
      useCallback(() => {
         setFilter({ ...filter, product_name: searchName });
         updateQuery({ productName: searchName });
      }, [searchName]),
      100
   );

   useEffect(() => {
      const q = queryString.parse(history.location.search);
      setSearchName((q.productName || "") as string);
      setFilter({
         ...filter,
         company_id: +(q.pharmacyId || "") as number,
         product_name: q.productName as string,
      });
   }, []);

   return {
      filteredPharmacies,
      productList,
      searchPharmacy: query.q,
      filter,
      isFetching,
      fetchPharmacies,
      onChangeProductNameFilter,
      totalData,
      searchName,
      setSearchName,
      onNavProductDetail,
      onChangePharmacy,
      onChangeSearchPharmacyFilter,
      setSearchPharmacy: onChangeSearchPharmacyFilter,
   };
};
