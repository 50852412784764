/* eslint-disable react-hooks/exhaustive-deps */
import { Select, Space, Switch, Tag } from "antd";
import moment from "moment";
import { format } from "path";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GridTables, { HeaderTableFormat, TagList } from "./TableFeatureFlag";
import { getPharmacyGroupListAPI } from "./utils/network";

const { Option } = Select;

const TabFeatureFlag = () => {
   const [option, setOption] = useState([]);
   const [loadingOption, setLoadingOption] = useState(false);
   const history = useHistory();

   const getPharmacyGroupList = async () => {
      setLoadingOption(true);
      try {
         const listPharmacy = await getPharmacyGroupListAPI();
         const reduceData = listPharmacy?.collection?.reduce(
            (collectData: { id: number; name: string }[], newItem: any) => [
               ...collectData,
               { id: newItem?.id, name: newItem?.name },
            ],
            []
         );
         setOption(reduceData?.length ? reduceData : []);
         setLoadingOption(false);
      } catch (err) {
         setLoadingOption(false);
      }
   };

   useEffect(() => {
      if (history.location.pathname.includes("feature-flag")) {
         getPharmacyGroupList();
      }
   }, [history.location.pathname]);

   const titles: HeaderTableFormat[] = [
      {
         name: "Features Key",
         span: 2,
         keys: "feature_key",
         noEdit: true,
      },
      {
         name: "Publish",
         span: 2,
         keys: "is_publish",
         custom: (e: any) => {
            let values = e.isEditOrAdd ? e.form?.is_publish : e.is_publish;
            return (
               <Switch
                  size='small'
                  disabled={!e.isEditOrAdd}
                  onClick={(el: any) => e.setForm({ ...e?.form, is_publish: el })}
                  checked={values}
                  checkedChildren='On'
                  unCheckedChildren='Off'
               />
            );
         },
      },
      {
         name: "Groups",
         span: 6,
         keys: "groups",
         custom: (e: any) => {
            const groupsData = e?.groups ? e?.groups : [];
            const defaultSelected = groupsData?.reduce(
               (collect: number[], newData: any) => [...collect, newData?.id],
               []
            );
            const list = groupsData?.reduce(
               (collect: { name: string; id: number }[], item: any) => [
                  ...collect,
                  { id: item?.id, name: item?.name },
               ],
               []
            );
            const setNewFormData = () => {
               e.setForm({ ...e.form, groups: defaultSelected || [] });
            };

            const setFormValue = (data: any) => {
               if (data?.includes("")) {
                  if (data.indexOf("") !== 0) {
                     e?.setForm({ ...e.form, groups: [] });
                  } else {
                     e?.setForm({ ...e.form, groups: [data[1]] });
                  }
               } else {
                  e?.setForm({ ...e.form, groups: data });
               }
            };
            return (
               <TagList
                  noSelectedValue={"All Companies"}
                  loading={loadingOption}
                  formValue={e.form.groups}
                  setFormValue={setFormValue || []}
                  setNewFormData={setNewFormData}
                  option={[...option]}
                  defaultSelected={defaultSelected}
                  e={e}
                  list={list}
               />
            );
         },
      },
      {
         name: "Created at",
         span: 3,
         keys: "created",
         custom: (e: any) => {
            return (
               <div>
                  {moment(e.created_at).format("DD-MM-YYYY")}
                  {/* <p>By {e.add ? 'You' : e.created_by}</p> */}
               </div>
            );
         },
      },
      {
         name: "Prerequisite",
         span: 4,
         keys: "prerequisite",
         custom: (e: any) => {
            const mapFeatureOptions = e.featureOptionsAsMap;
            if (!e.isEditOrAdd) {
               const tags: string[] = e?.prerequisite || [];

               if (!tags.length) {
                  return "Tidak ada prerequisite";
               }

               return tags.map((t, index) => (
                  <Tag color='green' key={`tag-${index}`} style={{ marginTop: "4px" }}>
                     {mapFeatureOptions.get(t)}
                  </Tag>
               ));
            }

            const filteredOptions = () => {
               return e.featureOptions.filter((f: any) => e.id.toString() !== f.value);
            };

            return (
               <Select
                  mode='multiple'
                  style={{ width: "100%" }}
                  placeholder='Pilih feature flag'
                  defaultValue={e.form?.prerequisite || []}
                  onChange={(val) => e?.setForm({ ...e.form, prerequisite: val })}
                  optionLabelProp='label'
                  filterOption={(inputValue, option) => {
                     return (option?.label as string)
                        ?.toLowerCase()
                        ?.includes(inputValue.toLowerCase());
                  }}
                  disabled={!e.isEditOrAdd}
                  options={filteredOptions()}
               />
            );
         },
      },
      { name: "Description", span: 3, keys: "description" },
   ];

   const initialForm = {
      is_publish: false,
      groups: [],
      prerequisite: [],
      description: "",
   };

   return (
      <div style={{ background: "white", padding: "4px", borderRadius: "5px" }}>
         <GridTables
            header={titles}
            searchKey={["feature_key", "description"]}
            endPoint='/system/feature'
            useCRUD={{
               initialForm,
               api: "",
               span: 4,
               add: false,
               edit: true,
               delete: false,
            }}
         />
      </div>
   );
};

export default TabFeatureFlag;
